<template lang="pug">
  div
    .row.justify-content-center
      .col-11.header
        div(style="text-align: center")
          img(class="client_logo" :src="Logo" v-openlog)

    .main_box
      .main_text Tu llamada
      .main_text ha finalizado
      div(style="font-size: 1.2rem; margin-top: 3rem;") Ya puedes cerrar esta ventana
</template>

<script>
import { mapActions, mapState } from "vuex";
import LogoColmedica from "@/assets/colmedica_logo.png";

export default {
  name: "CancelledAgent",

  created() {
    this.startupLocal();
  },

  beforeDestroy() {
    this.clearLog();
  },

  data: () => ({
    LogoColmedica
  }),

  computed: {
    ...mapState(["env"]),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },
    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    }
  },

  methods: {
    ...mapActions({
      cancelTurn: "cancelTurn",
      endMeet: "meeting/endMeet",
      putLog: "log/put",
      clearLog: "log/clear"
    }),

    async startupLocal() {
      try {
        await this.cancelTurn({ local: true });
        this.putLog({
          name: "Canceled. Turno cancelado."
        });
      } catch (error) {
        this.putLog({
          name: "Canceled. Error no restrictivo. No se pudo cancelar el turno.",
          checked: false,
          message: error
        });
        console.error("Error in endMeet in canceled page", error);
      }
      try {
        await this.endMeet();
        this.putLog({
          name: "Canceled. Videollamada cancelada."
        });
      } catch (error) {
        this.putLog({
          name:
            "Canceled. Error no restrictivo. No se pudo cancelar la videollamada.",
          checked: false,
          message: error
        });
        console.error("Error in endMeet in canceled page", error);
      }
    }
  }
};
</script>
<style scoped>
.main_text {
  font-size: 1.5em;
}
.main_box {
  width: 100%;
  margin: auto;
  text-align: center;
  font-size: 1em;
  color: black;
}
.client_logo {
  width: 140px;
  height: auto;
}
.header {
  margin-top: 3vh;
  margin-bottom: 3vh;
}
.text-header-container {
  position: relative;
  height: 10vh;
  width: 50%;
  float: left;
}
.turn-detail {
  padding: 3vh;
  font-size: 1.5em;
  font-weight: 600;
}
.div_center_button {
  position: absolute;
  bottom: 3vh;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
  width: 90%;
}
</style>
